@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  width: 100%;
  color: black;

  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }

  .Login .form-group {
      text-align: left;
  }
}
