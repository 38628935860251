.Main {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  width: 100%;
  color: black;
}

h1 {
    margin-top: 10px !important;
}
